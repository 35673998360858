@font-face {
    font-family: 'NewAcademy';
    src: local('NewAcademy'),
        url('./assets/fonts/NEWACADEMY.ttf') format('truetype');
}


::-webkit-scrollbar {
    width: 11px;
    height: 11px;

}

/* Track */
::-webkit-scrollbar-track {
    background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(196, 196, 196);
    border-radius: 5px;
    border-top: 4px solid #f0f2f5;

    border: 2px solid #f0f2f5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(155, 153, 153);
}

.MuiPaper-root::-webkit-scrollbar-thumb {
    background: rgb(196, 196, 196);
    border-radius: 5px;
    border-top: 4px solid #fff;

    border: 2px solid #fff;
}

/* #react-select-2-listbox, #react-select-3-listbox, #react-select-14-listbox, #react-select-5-listbox, #react-select-13-listbox {
    z-index: 99999 !important;

} */


div[id^='react-select-'], div[id*='react-select-'] {
    z-index: 99999 !important;
}

.MuiModal-root {
    z-index: 99999999 !important;
}

.unauth-background {
    background-image: url(./assets/images/main-bg.png);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 56% auto;
}

@media only screen and (max-width: 786px) {
    .unauth-background {
        background-size: 100% auto !important;
    }
}

.editing-row {
    pointer-events: none;
    /* Add any additional styles to indicate that the row is disabled */
}